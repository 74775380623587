import { createRouter, createWebHistory, RouteLocationNormalized, RouteLocationNormalizedLoadedGeneric, RouteRecordRaw } from 'vue-router';
import { useUserService } from '../services/userDataService';
import { useUserStore } from '../stores/userStore';
import { envs } from '../env';
import { routes as empireRoutes } from '../views/empire/routes';
import { routes as automationsuiteRoutes } from '../views/automationsuite/routes';
import { routes as dbmRoutes } from '../views/dbm/routes';
import { routes as rocknrollRoutes } from '../views/rocknroll/routes';
import { routes as iconcommunityRoutes } from '../views/iconcommunity/routes';
import { routes as workflow } from '../views/workflow/routes';
import { routes as bombshell } from '../views/bombshell/routes';
import { routes as makelifewowteamRoutes } from '../views/makelifewowteam/routes';
import { routes as impactRoutes } from '../views/impact/routes';
import { routes as goldenglobalteamRoutes } from '../views/goldenglobalteam/routes';
import { routes as diamondclubRoutes } from '../views/diamondclub/routes';
import { routes as farmasiteamRoutes } from '../views/farmasiteam/routes';
import { routes as teamgoatRoutes } from '../views/teamgoat/routes';
import { routes as soluniRoutes } from '../views/soluni/routes';
import { routes as ketoretoRoutes } from '../views/ketoreto/routes';
import { routes as positiveglobalchangeRoutes } from '../views/positiveglobalchange/routes';
import { routes as frazerRoutes } from '../views/frazer/routes';
import { TenantDTO, useTenantService } from '@/services/tenantService';
import { useRouterHelpers } from './useRouterHelpers';
import { routes as sharedRoutes } from '../views/shared/routes';
import { AccountLanguage } from '@/services/useLanguageService';

export const availableRoutesArray = [
  'MainLayout',
  'LandingPage',
  'DashboardHome',
  'ProfilePage',
  'ViewAllLeads',
  'LeadsPage',
  'ManageSubscription',
  'SagaMessagingPage',
  'ImportContacts',
  'HostLogin',
  'SupportCenter',
  'ForgotPassword',
  'RegisterPage',
  'HelpCenter',
  'ChatBotRedirect',
  'LibraryPage',
  'LibraryCategoryContentPage',
  'PageNotFound',
  'FastMessagingPage',
  'MassMessagingPage',
  'LinkMateLeadPage',
  'FollowUpPage',
  'LinkMateLayout',
  'LinkMateOnboarding',
  'LinkMatePage',
  'OnBoardingPage',
  'DMOPage',
  'ProductInformationFlow',
  'ProductInfoPageQuestionaire',
  'BusinessInfoPageQuestionaire',
  'BusinessInformationFlow',
  'KetoinfoSolutionPage',
  'OpportunitySolutionPage',
  'UserflowHelp',
  'ProspectingToolsSettingsPage',
  'UnicornPage',
  'BroadcastPage',
  'EmailListsPage',
  'LeadMagnetPage',
  'InfoPageLinkPage',
  'BusinessInfoPageLinkPage',
  'ProductInfoPageLinkPage',
  'LeadMagnetLinkPage',
  'WelcomePage',
  'StatisticsPage',
  'StripeCheckoutPage',
  'ExternalVideoPage',
  'PricingPage',
  'ProductInformationFlowCollagen',
  'ProductInformationFlowNutrition',
  'BusinessInformationFlowWarm',
  'BusinessInformationFlowCold',
  'ExternalInfoPageCold',
  'ExternalInfoPageWarm',
  'LeaderBoardPage',
  'AnalyticsPage',
  'AdminWelcomePage',
] as const;

export type AvailableRoutes = (typeof availableRoutesArray)[number];

export type AdminResourceName =
  | 'adminWelcome'
  | 'dashboard'
  | 'library'
  | 'onboarding'
  | 'dmo'
  | 'businessInfoPage'
  | 'productInfoPage'
  | 'statistics'
  | 'leadMagnets';

export type RouteWithName = RouteRecordRaw & { name?: AvailableRoutes; children?: RouteWithName[] };
declare module 'vue-router' {
  interface RouteMeta {
    // must be declared by every route
    requiresAuth: boolean;
    isFullPage?: boolean;
    hasAdminPage?: boolean;
    resourceName?: AdminResourceName;
  }
}

const VITE_LINKMATE_PREFIX = envs.VITE_LINKMATE_PREFIX;

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...sharedRoutes,
    ...empireRoutes,
    ...automationsuiteRoutes,
    ...dbmRoutes,
    ...rocknrollRoutes,
    ...iconcommunityRoutes,
    ...workflow,
    ...bombshell,
    ...makelifewowteamRoutes,
    ...impactRoutes,
    ...goldenglobalteamRoutes,
    ...diamondclubRoutes,
    ...farmasiteamRoutes,
    ...teamgoatRoutes,
    ...soluniRoutes,
    ...ketoretoRoutes,
    ...positiveglobalchangeRoutes,
    ...frazerRoutes,
  ],
  scrollBehavior(to) {
    if (to.hash) {
      const section = document.querySelector(to.hash);
      section?.scrollIntoView({ behavior: 'smooth' });
    } else window.document.getElementById('main-route')?.scrollTo({ top: 0 });
  },
});

router.beforeEach(async (to, from, next) => {
  const tenantService = useTenantService();
  const { getRouteName, getNewTenantPath } = useRouterHelpers();

  // Redirect to linkmate if needed
  if (
    window.location.origin === `${VITE_LINKMATE_PREFIX}` &&
    !to.path.includes('/link') &&
    (to.name != getRouteName('PageNotFound') || from.name === undefined)
  ) {
    const redirectPath = '/link' + to.fullPath;
    next(redirectPath);
    return;
  }

  const userStore = useUserStore();

  if (to.name === getRouteName('RegisterPage') && userStore.loggedInInfo?.jwt) {
    next({ name: getRouteName('DashboardHome') });
    return;
  }

  if (useUserService().isUpdateNeeded()) {
    await userStore.updateLoggedInUserInfo();
  }

  // Redirect to correct tenant if needed
  const tenant = await computeTenant(to, userStore.loggedInInfo?.user?.tenant);
  if (tenant && !tenantService.isCorrectTenant(tenant.tenant)) {
    tenantService.setTenant(tenant.tenant);
    const newTenantPath = getNewTenantPath(tenant.tenant, to.fullPath);
    next(newTenantPath);
    return;
  }

  // If public page proceed
  if (!to.meta.requiresAuth || to.name === getRouteName('HelpCenter')) {
    next();
    return;
  }

  // If user is not logged in redirect to login page
  if (!userStore.loggedInInfo?.jwt || !userStore.loggedInInfo?.user) {
    next({ name: getRouteName('HostLogin'), query: { redirect: to.fullPath } });
    return;
  }

  // If user has not completed checkout once redirect to stripe checkout page
  if (
    !userStore.subscriptionsStatus.isUserSubscribed &&
    userStore.subscriptionsStatus.status === 'incomplete_expired' &&
    to.name !== getRouteName('StripeCheckoutPage')
  ) {
    next({ name: getRouteName('StripeCheckoutPage') });
    return;
  }

  if (to.name === getRouteName('WelcomePage') && userStore.loggedInInfo.user.hasBeenWelcomed) {
    next({ name: getRouteName('DashboardHome') });
    return;
  }

  if (!userStore.subscriptionsStatus.isUserSubscribed && to.name != getRouteName('ManageSubscription')) {
    next({ name: getRouteName('ManageSubscription') });
    return;
  }
  if (userStore.adminStatus.isAdminModeActive && !to.meta.hasAdminPage) {
    next({ name: getRouteName('AdminWelcomePage') });
    return;
  }
  if (userStore.adminStatus.isAdminModeActive && to.meta.hasAdminPage) {
    const isResourceAvailable = await handleAdminRouteChecker(to, userStore.adminStatus.adminModeLanguage);
    if (!isResourceAvailable) {
      from.name ? next(false) : next({ name: getRouteName('AdminWelcomePage') });
      return;
    }
    next();
    return;
  }
  if (to.name === getRouteName('StripeCheckoutPage') && userStore.subscriptionsStatus.isUserSubscribed) {
    next({ name: getRouteName('WelcomePage') });
    return;
  }
  const isFeatureBlocked = handleFeatureFlags(to, getRouteName);
  if (isFeatureBlocked) {
    next({ name: getRouteName('PricingPage'), query: { redirectPage: to.name?.toString() } });
    return;
  }
  next();
});

router.onError(error => {
  const isChunkLoadFailedWithMessage =
    error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed');
  if (isChunkLoadFailedWithMessage) {
    const response = confirm('New updates are available, please reload the page');
    if (response) {
      window.location.reload();
    }
  }
  return;
});

const handleFeatureFlags = (to: RouteLocationNormalized, getRouteName: (name: AvailableRoutes) => string) => {
  const userStore = useUserStore();
  if (to.name === getRouteName('DMOPage') && !userStore.subscriptionsStatus.features.includes('dmo')) {
    return true;
  }
  if (to.name === getRouteName('BroadcastPage') && !userStore.subscriptionsStatus.features.includes('email_broadcast')) {
    return true;
  }
  if (to.name === getRouteName('EmailListsPage') && !userStore.subscriptionsStatus.features.includes('email_list')) {
    return true;
  }
  return;
};

const computeTenant = async (toRoute: RouteLocationNormalized, loggedInTenant?: TenantDTO) => {
  if (!toRoute.path.includes('/link') || !toRoute.params.linkName || typeof toRoute.params.linkName !== 'string') {
    return loggedInTenant;
  }
  const { getUserFromLinkMateNickName } = useUserService();
  const user = await getUserFromLinkMateNickName(toRoute.params.linkName);
  return user?.tenant;
};

export const handleAdminRouteChecker = async (to: RouteLocationNormalized, locale?: AccountLanguage) => {
  const { checkoutAdminRouteOnTenant } = useTenantService();
  const resourceName = to.meta.resourceName;
  if (!resourceName) return false;
  return await checkoutAdminRouteOnTenant(resourceName, locale);
};

export default router;
