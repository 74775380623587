/// <reference types="vite/client" />

import { z } from 'zod';
export const tenantsArray = [
  'empire',
  'automationsuite',
  'dbm',
  'rocknroll',
  'iconcommunity',
  'workflow',
  'bombshell',
  'makelifewowteam',
  'impact',
  'goldenglobalteam',
  'diamondclub',
  'farmasiteam',
  'teamgoat',
  'soluni',
  'ketoreto',
  'positiveglobalchange',
  'frazer',
] as const;

const envSchema = z.object({
  VITE_API_URL: z.string(),
  VITE_CONTACT_ME_FORM_API_TOKEN: z.string(),
  VITE_IS_STAGING_ENV: z.enum(['true', 'false']),
  VITE_LINKMATE_PREFIX: z.string(),
  VITE_OUTSETA_URL: z.string(),
  VITE_USERFLOW_TOKEN: z.string(),
  VITE_BASE_URL: z.string(),
  VITE_SAGA_HELP_LINK: z.string(),
  VITE_UNICORN_PAGE_URL: z.string(),
  VITE_FIREBASE_ENV: z.enum(['staging', 'master']),
  VITE_STRIPE_PUBLISHABLE_KEY: z.string(),
  VITE_POSTHOG_API_KEY: z.string(),
});

type ImportMetaEnv = z.infer<typeof envSchema>;

export interface ImportMeta {
  readonly env: ImportMetaEnv;
}

export const envs = envSchema.parse(import.meta.env);
