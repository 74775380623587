import http, { StrapiDTO, StrapiObjectBody, StrapiResponseDTO, useAxiosUtils } from './axiosClient';
import { useUserStore } from '../stores/userStore';
import { AxiosResponse } from 'axios';
import { useUtilsService } from './utilsService';
import qs from 'qs';
import { PaginationOptions } from './useLeadsTable';
import { ContactTagDTO } from './contactTagService';
import { UserDTO } from './userDataService';
import { EmailList } from './emailListsService';

export const FREE_LEAD_LIMIT_INDEX = 2;

export const LeadInterestArray = ['product', 'business'] as const;
export type LeadInterest = (typeof LeadInterestArray)[number];

export function isLeadInterest(value: string): value is LeadInterest {
  return LeadInterestArray.includes(value as LeadInterest);
}

export type SourceOptions =
  | 'CSV'
  | 'Manually Created'
  | 'CONTACT_ME_FORM'
  | 'INFORMATION_FLOW_FORM'
  | 'INFORMATION_FLOW'
  | 'LEAD_MAGNET'
  | 'US bot'
  | 'DE bot'
  | 'ES bot'
  | 'EN bot';
export interface LeadInterface {
  fullName?: string;
  id?: number;
  cellphone?: string;
  country?: string;
  createdAt?: string;
  email?: string;
  publishedAt?: string;
  instagram?: string;
  facebook?: string;
  interest?: LeadInterest;
  owner?: UserDTO;
  updatedAt?: string;
  membership?: LeadMembership;
  source?: SourceOptions;
  creationDateExternal?: string;
  lastFollowUp?: string;
  scheduledFollowUp?: string | null;
  lastInteraction?: string | null;
  extendedSource?: ExtendedSource;
  lastOrder?: string;
  requestedFollowUp?: boolean;
  contactTag?: StrapiObjectBody<ContactTagDTO | null>;
  emailLists?: StrapiObjectBody<StrapiDTO<Omit<EmailList, 'leads'>>[]>;
  leadLanguage?: string;
  interactions?: string;
  searchableName?: string;
  countryCode?: string | null;
}

export const leadMembershipArray = ['Lead', 'User', 'Customer', 'Affiliate', 'Smartship', 'Promoter', 'Lost'] as const;
export type LeadMembership = (typeof leadMembershipArray)[number];
export function isLeadMembership(value: string): value is LeadMembership {
  return leadMembershipArray.includes(value as LeadMembership);
}

export interface LeadDTO {
  attributes: LeadInterface;
  id: number;
}

export interface LeadsPerMonthData {
  value: number;
  month: number;
  year: number;
}

export interface LeadsPerMonth {
  attributes: LeadsPerMonthData;
  id: number;
}
export interface LeadPostOut extends Omit<LeadInterface, 'owner' | 'email' | 'contactTag'> {
  email?: string | null;
  owner?: number;
  contactTag?: null | number | StrapiObjectBody<ContactTagDTO | null>;
}

export type InfoPageExtendedSource =
  | 'INFORMATION_FLOW_LINKMATE'
  | 'INFORMATION_FLOW_FORM_LINKMATE'
  | 'INFORMATION_FLOW_DIRECT'
  | 'INFORMATION_FLOW_FORM_DIRECT';

export type ExtendedSource = InfoPageExtendedSource | 'EXTERNAL_LEAD_MAGNET' | 'CONTACT_API_PICKER' | 'VCF_FILE' | 'INSTAGRAM_IMPORT';

export type LeadPutOut = Partial<LeadPostOut> & { isManualUpdate: boolean };

export interface PostLeadOption {
  useApiToken?: boolean;
}

export const strapiResponseLimit = 100;
export const populateTag = { contactTag: true };
export const emailListPopulate = { emailLists: true };

export function useLeadDataService() {
  const userStore = useUserStore();
  const { getHeaders, getApiToken } = useAxiosUtils();
  const { getDateMidday, getNowDateMidday, getEndOfTheDay } = useUtilsService();

  async function getLoggedInUsersLeads(start: number, limit: number) {
    const query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          membership: {
            $ne: 'Lost',
          },
        },
        pagination: {
          start: start,
          limit: limit,
        },
        populate: populateTag,
        sort: ['publishedAt:desc'],
      },
      { encodeValuesOnly: true }
    );
    return (await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders())).data;
  }
  async function getLeadsFilteredByEmailList(emailListId: number, start: number, limit: number, filter?: string) {
    let query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          emailLists: {
            id: {
              $in: emailListId,
            },
          },
        },
        pagination: {
          start: start,
          limit: limit,
        },
        populate: populateTag,
        sort: ['createdAt:desc'],
      },
      { encodeValuesOnly: true }
    );
    query = filter?.length ? query + '&' + filter : query;
    return (await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders())).data;
  }

  async function getLeadsExcludingEmailList(
    emailListToExcludeId: number,
    start: number,
    limit: number,
    totalLeadsPresentInEmailList: number,
    filter?: string
  ) {
    let filteredLeads: LeadDTO[] = [];
    let totalLeads = 0;
    let totalLeadsRetrievable = 0;

    do {
      let query = qs.stringify(
        {
          filters: {
            owner: {
              id: {
                $eq: userStore.loggedInInfo?.user?.id,
              },
            },
          },
          pagination: {
            start: start,
            limit: limit,
          },
          populate: { ...populateTag, ...emailListPopulate },
          sort: ['createdAt:desc'],
        },
        { encodeValuesOnly: true }
      );
      query = (filter?.length ? filter + '&' : '') + query;
      const tempLeads = await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders());
      filteredLeads.push(
        ...tempLeads.data.data.filter(lead => lead.attributes.emailLists?.data.every(list => list.id !== emailListToExcludeId))
      );
      start += tempLeads.data.data.length;
      totalLeads = tempLeads.data.meta?.pagination?.total || 0;
      totalLeadsRetrievable = totalLeads - totalLeadsPresentInEmailList;
    } while (
      filteredLeads.length < limit &&
      filteredLeads.length < totalLeadsRetrievable &&
      start < totalLeads &&
      totalLeadsPresentInEmailList < totalLeads
    );

    const diff = filteredLeads.length - limit;
    if (diff > 0) {
      filteredLeads = filteredLeads.slice(0, limit);
      start = start - diff;
    }

    return {
      data: filteredLeads,
      meta: {
        pagination: {
          total: totalLeadsRetrievable,
          limit,
          start,
        },
      },
    };
  }

  async function getLoggedInUsersLeadsCountFilteredForLeadMagnetsConversions() {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          $and: [
            {
              source: {
                $eq: 'LEAD_MAGNET',
              },
            },
            {
              publishedAt: {
                $gte: startOfMonth,
              },
            },
          ],
        },
        pagination: {
          start: 0,
          limit: 0,
        },
      },
      { encodeValuesOnly: true }
    );
    const res = await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders());
    return res.data.meta?.pagination?.total || 0;
  }

  async function getLoggedInUsersLeadsCountFilteredForInfoPagesConversions() {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          $and: [
            {
              source: {
                $eq: ['INFORMATION_FLOW_FORM', 'INFORMATION_FLOW'],
              },
            },
            {
              publishedAt: {
                $gte: startOfMonth,
              },
            },
          ],
        },
        pagination: {
          start: 0,
          limit: 0,
        },
        sort: ['createdAt:desc'],
      },
      { encodeValuesOnly: true }
    );
    const res = await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders());
    return res.data.meta?.pagination?.total || 0;
  }

  async function getLoggedInUsersLeadsCountFilteredForLinkMateConversions() {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          $and: [
            {
              $or: [
                {
                  source: {
                    $eq: ['LEAD_MAGNET', 'CONTACT_ME_FORM'],
                  },
                },
                {
                  extendedSource: {
                    $eq: ['INFORMATION_FLOW_LINKMATE', 'INFORMATION_FLOW_FORM_LINKMATE'],
                  },
                },
              ],
            },
            {
              publishedAt: {
                $gte: startOfMonth,
              },
            },
          ],
        },
        pagination: {
          start: 0,
          limit: 0,
        },
        sort: ['createdAt:desc'],
      },
      { encodeValuesOnly: true }
    );
    return http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders());
  }

  async function getLoggedInUsersLeadsFilteredByMembership(memberships: LeadMembership[], start: number, limit: number) {
    const query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          membership: {
            $in: memberships,
          },
        },
        pagination: {
          start: start,
          limit: limit,
        },
        populate: populateTag,
        sort: ['publishedAt:desc'],
      },
      { encodeValuesOnly: true }
    );

    return (await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders())).data;
  }

  const getLoggedInUsersLeadsFiltered = async (filter: string, start: number, limit: number) => {
    const query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          membership: {
            $ne: 'Lost',
          },
        },
        pagination: {
          start: start,
          limit: limit,
        },
        populate: populateTag,
        sort: ['publishedAt:desc'],
      },
      { encodeValuesOnly: true }
    );
    return (await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}&${filter}`, getHeaders())).data;
  };

  async function getCurrentMonthLeadsCountByMembership(membership: LeadMembership) {
    const now = new Date();
    const next = new Date(now.getFullYear(), now.getMonth(), 1);
    const from = next.getTime();

    const query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          membership: {
            $eq: membership,
          },
          publishedAt: {
            $gte: from,
          },
        },
        pagination: {
          start: 0,
          limit: 0,
        },
        sort: ['publishedAt:desc'],
      },
      { encodeValuesOnly: true }
    );

    const res = await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders());
    return res.data.meta?.pagination?.total || 0;
  }

  async function getPreviousMonthLeadsCountByMembership(membership: LeadMembership) {
    const now = new Date();
    const previousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const currentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    const from = previousMonth.getTime();
    const to = currentMonth.getTime();

    const query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          membership: {
            $eq: membership,
          },
          $and: [
            {
              publishedAt: {
                $gte: from,
              },
            },
            {
              publishedAt: {
                $lt: to,
              },
            },
          ],
        },
        pagination: {
          start: 0,
          limit: 0,
        },
        sort: ['publishedAt:desc'],
      },
      { encodeValuesOnly: true }
    );

    const res = await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders());
    return res.data.meta?.pagination?.total || 0;
  }

  async function getLeadsCountWithOrdersInCurrentMonth() {
    const now = new Date();
    const next = new Date(now.getFullYear(), now.getMonth(), 1, 0);
    const from = next.getTime();

    const query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          lastOrder: {
            $gte: from,
          },
        },
        pagination: {
          start: 0,
          limit: 0,
        },
        sort: ['publishedAt:desc'],
      },
      { encodeValuesOnly: true }
    );

    const res = await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders());
    return res.data.meta?.pagination?.total || 0;
  }

  async function getLeadsCount(userId: number, filters?: string): Promise<number> {
    let query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userId,
            },
          },
        },
        pagination: {
          start: 0,
          limit: 0,
        },
        sort: ['createdAt:desc'],
      },
      { encodeValuesOnly: true }
    );
    if (filters) {
      query += '&' + filters;
    }

    const res = await http.get<StrapiResponseDTO<number>>(`/api/leads?${query}`, getHeaders());
    return res.data.meta?.pagination?.total || 0;
  }

  async function getLeadsWithTodaySchedule(filters?: string, pagination: PaginationOptions = { limit: 10, start: 0 }) {
    const now = new Date();
    const today = getEndOfTheDay(now);
    let query = '';
    if (filters) {
      query += filters + '&';
    }
    query += qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          scheduledFollowUp: {
            $lte: today,
          },
          membership: {
            $ne: 'Lost',
          },
        },
        pagination,
        populate: populateTag,
        sort: ['scheduledFollowUp:asc'],
      },
      { encodeValuesOnly: true }
    );
    const res = await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders());
    return res.data;
  }

  async function getLeadsWithRequestedSchedule(filters?: string, pagination: PaginationOptions = { limit: 10, start: 0 }) {
    let query = '';
    if (filters) {
      query += filters + '&';
    }
    query += qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          requestedFollowUp: {
            $eq: true,
          },
          membership: {
            $ne: 'Lost',
          },
        },
        pagination,
        populate: populateTag,
      },
      { encodeValuesOnly: true }
    );

    const res = await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders());
    return res.data;
  }

  async function getLeadsWithUpcomingSchedule(filters?: string, pagination: PaginationOptions = { limit: 10, start: 0 }) {
    const now = getNowDateMidday();
    const tomorrow = getDateMidday(now.getFullYear(), now.getMonth(), now.getDate() + 1);

    let query = '';
    if (filters) {
      query += filters + '&';
    }
    query += qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          scheduledFollowUp: {
            $gte: tomorrow,
          },
          membership: {
            $ne: 'Lost',
          },
        },
        pagination,
        populate: populateTag,
        sort: ['scheduledFollowUp:asc'],
      },
      { encodeValuesOnly: true }
    );

    const res = await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders());

    return res.data;
  }

  async function getChartData() {
    const now = new Date();
    const currentYear = now.getFullYear();
    const previousYear = now.getFullYear() - 1;

    const query = qs.stringify(
      {
        fields: ['value', 'month', 'year'],
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          year: {
            $in: [currentYear, previousYear],
          },
        },
      },
      { encodeValuesOnly: true }
    );

    const res = await http.get<StrapiResponseDTO<LeadsPerMonth[]>>(`/api/leads-per-months?${query}`, getHeaders());
    return res.data.data;
  }

  const removeAccentsAndLowercase = (input: string) => {
    return input
      .normalize('NFKD')
      .replace(/[\u0300-\u036fÀ-ÿðÐøØłŁıªºÞþ]/g, match => {
        switch (match) {
          case 'ø':
          case 'Ø':
            return 'o';
          case 'ł':
          case 'Ł':
            return 'l';
          case 'ð':
          case 'Ð':
            return 'd';
          case 'ı':
            return 'i';
          case 'ª':
            return 'a';
          case 'º':
            return 'o';
          case 'Þ':
            return 'th';
          case 'þ':
            return 'th';
          default:
            return '';
        }
      })
      .toLowerCase();
  };

  function handleCreateSearchFilter(searchString?: string) {
    if (!searchString) {
      return getQuery([]);
    }
    const array: Partial<Record<keyof LeadInterface, { $containsi?: string }>>[] = [
      { searchableName: { $containsi: removeAccentsAndLowercase(searchString) } },
      { fullName: { $containsi: searchString } },
      { email: { $containsi: searchString } },
      { instagram: { $containsi: searchString } },
    ];
    const regEx = /\d+/;
    if (regEx.test(searchString)) {
      array.push({ cellphone: { $containsi: searchString } });
    }
    return getQuery(array);
  }

  const getQuery = (searchFilters: Partial<Record<keyof LeadInterface, { $containsi?: string }>>[]) => {
    return qs.stringify(
      {
        filters: {
          $or: searchFilters,
        },
      },
      { encodeValuesOnly: true }
    );
  };

  function getLead(id: number) {
    const query = qs.stringify(
      {
        populate: { ...populateTag, ...emailListPopulate },
      },
      { encodeValuesOnly: true }
    );
    return http.get<StrapiResponseDTO<LeadDTO>>(`/api/leads/${id}?${query}`, getHeaders());
  }
  function postLead(obj: StrapiObjectBody<LeadPostOut>, options?: PostLeadOption) {
    obj.data.cellphone = useUtilsService().trimPhoneNumer(obj.data.cellphone);
    const authType = options?.useApiToken ? getApiToken() : getHeaders();
    return http.post<StrapiResponseDTO<LeadDTO>>('/api/leads', obj, authType);
  }
  async function manuallyUpdateLead(
    id: number,
    obj: StrapiObjectBody<Omit<LeadPutOut, 'isManualUpdate'>>
  ): Promise<AxiosResponse<StrapiResponseDTO<LeadDTO>>> {
    const authType = getHeaders();
    obj.data.cellphone = useUtilsService().trimPhoneNumer(obj.data.cellphone);
    obj = {
      ...obj,
      data: {
        ...obj.data,
        isManualUpdate: true,
      },
    } as StrapiObjectBody<LeadPutOut>;
    const res = await http.put<StrapiResponseDTO<LeadDTO>>(`/api/leads/${id}`, obj, authType);
    return res;
  }

  async function updateLeadLastInteraction(id: number, lastInteraction: string) {
    const res = await http.put<StrapiResponseDTO<LeadDTO>>(
      `/api/leads/${id}`,
      {
        data: {
          lastInteraction,
        },
      },
      getHeaders()
    );
    return res;
  }

  async function deleteLead(id: number) {
    const res = await http.delete<StrapiResponseDTO<LeadDTO>>(`/api/leads/${id}`, getHeaders());
    return res.data;
  }

  const isMarkedCompleted = (lead: LeadDTO) => {
    if (!lead.attributes.lastFollowUp) {
      return false;
    }
    const now = getNowDateMidday();
    const lastFollowUp = new Date(lead.attributes.lastFollowUp);
    if (lastFollowUp.getDate() === now.getDate() && !lead.attributes.scheduledFollowUp) {
      return true;
    }
    return false;
  };

  const getCustomerSmartshipPromoterCountInCurrentMonth = async () => {
    const now = new Date();
    const next = new Date(now.getFullYear(), now.getMonth(), 1, 0);
    const from = next.getTime();

    const query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          membership: {
            $in: ['Customer', 'Smartship', 'Promoter'],
          },
          publishedAt: {
            $gte: from,
          },
        },
        pagination: {
          start: 0,
          limit: 0,
        },
      },
      { encodeValuesOnly: true }
    );

    const res = await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders());
    return res.data.meta?.pagination?.total || 0;
  };

  const getLeadsWithInitialMembershipLeadInCurrentMonth = async () => {
    const now = new Date();
    const next = new Date(now.getFullYear(), now.getMonth(), 1, 0);
    const from = next.getTime();

    const query = qs.stringify(
      {
        filters: {
          owner: {
            id: {
              $eq: userStore.loggedInInfo?.user?.id,
            },
          },
          initialMembership: {
            $eq: 'Lead',
          },
          publishedAt: {
            $gte: from,
          },
        },
        pagination: {
          start: 0,
          limit: 0,
        },
      },
      { encodeValuesOnly: true }
    );

    const res = await http.get<StrapiResponseDTO<LeadDTO[]>>(`/api/leads?${query}`, getHeaders());
    return res.data.meta?.pagination?.total || 0;
  };

  const getAllInteractions = async () => {
    const res = await http.get<{ interactions: string[] }>(`/api/leads/interactions`, getHeaders());
    return res.data.interactions.sort();
  };

  return {
    getLoggedInUsersLeads,
    getLoggedInUsersLeadsFilteredByMembership,
    getLoggedInUsersLeadsFiltered,
    getLeadsCount,
    getCurrentMonthLeadsCountByMembership,
    getPreviousMonthLeadsCountByMembership,
    getLead,
    getLoggedInUsersLeadsCountFilteredForLinkMateConversions,
    getLeadsCountWithOrdersInCurrentMonth,
    postLead,
    manuallyUpdateLead,
    deleteLead,
    getLeadsWithTodaySchedule,
    getLeadsWithUpcomingSchedule,
    getLeadsWithRequestedSchedule,
    getLeadsFilteredByEmailList,
    getLeadsExcludingEmailList,
    getChartData,
    isMarkedCompleted,
    handleCreateSearchFilter,
    getLoggedInUsersLeadsCountFilteredForInfoPagesConversions,
    getLoggedInUsersLeadsCountFilteredForLeadMagnetsConversions,
    getCustomerSmartshipPromoterCountInCurrentMonth,
    getLeadsWithInitialMembershipLeadInCurrentMonth,
    getAllInteractions,
    updateLeadLastInteraction,
  };
}
