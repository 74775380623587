import { RouteWithName } from '@/router';
import { leadMembershipArray, LeadMembership } from '@/services/leadDataService';
import { useUserStore } from '@/stores/userStore';
import { defineAsyncComponent } from 'vue';
import LoaderWithProgressBar from '@/components/LoaderWithProgressBar.vue';

const AsyncComponent = (importCallback: () => Promise<any>) =>
  defineAsyncComponent({
    loader: importCallback,
    loadingComponent: LoaderWithProgressBar,
    delay: 200,
  });

export const routes: RouteWithName[] = [
  {
    path: '/',
    name: 'MainLayout',
    component: AsyncComponent(() => import('./MainLayoutView.vue')),
    children: [
      {
        path: '',
        name: 'LandingPage',
        component: AsyncComponent(() => import('./LandingPageView.vue')),
        meta: {
          requiresAuth: false,
          isFullPage: true,
        },
      },
      {
        path: 'welcome',
        name: 'WelcomePage',
        component: AsyncComponent(() => import('../../pages/WelcomePage.vue')),
        meta: {
          requiresAuth: true,
          isFullPage: true,
        },
      },
      {
        path: 'adminWelcome',
        name: 'AdminWelcomePage',
        component: AsyncComponent(() => import('../../pages/AdminWelcomePage.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'adminWelcome',
        },
      },
      {
        path: `viewAllLeads/:membership*`,
        name: `ViewAllLeads`,
        props: true,
        component: AsyncComponent(() => import('../../pages/ViewAllLeads.vue')),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (leadMembershipArray.includes(to.params.membership[0] as LeadMembership)) {
            next();
            return;
          }
          next({ name: 'PageNotFound' });
        },
      },
      {
        path: 'dashboard',
        name: 'DashboardHome',
        component: AsyncComponent(() => import('../../pages/DashboardHome.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'dashboard',
        },
      },
      {
        path: 'profilePage',
        name: 'ProfilePage',
        component: AsyncComponent(() => import('../../pages/ProfilePage.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'leadsPage',
        name: 'LeadsPage',
        component: AsyncComponent(() => import('../../pages/LeadsPage.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'leaderboard',
        name: 'LeaderBoardPage',
        component: AsyncComponent(() => import('../../pages/LeaderBoardPage.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'manageSubscription/:checkoutSessionId?',
        name: 'ManageSubscription',
        component: AsyncComponent(() => import('../../pages/ManageSubscription.vue')),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: 'pricingPage',
        name: 'PricingPage',
        component: () => import('@/pages/PricingPage.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: 'sagaMessaging',
        name: 'SagaMessagingPage',
        component: AsyncComponent(() => import('../../pages/SagaMessagingPage.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'massMessaging',
        name: 'MassMessagingPage',
        component: AsyncComponent(() => import('../../pages/MassMessagingPage.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'fastMessaging',
        name: 'FastMessagingPage',
        component: AsyncComponent(() => import('../../pages/FastMessagingPage.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'linkMateStart',
        name: 'LinkMateOnboarding',
        component: AsyncComponent(() => import('../../pages/linkMate/LinkMateOnboarding.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'followUp',
        redirect: '/followUp/TODAY',
      },
      {
        path: 'followUp/:filterSchedule',
        name: 'FollowUpPage',
        component: AsyncComponent(() => import('../../pages/FollowUpPage.vue')),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'importContacts',
        name: 'ImportContacts',
        component: AsyncComponent(() => import('../../pages/ImportContactsPage.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'helpCenter',
        name: 'HelpCenter',
        component: AsyncComponent(() => import('../../pages/HelpCenter.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'library',
        name: 'LibraryPage',
        component: AsyncComponent(() => import('../../pages/LibraryPage.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'library',
        },
      },
      {
        path: 'library/category/:categoryId',
        name: 'LibraryCategoryContentPage',
        component: AsyncComponent(() => import('../../pages/LibraryCategoryContentPage.vue')),
        props: true,
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'library',
        },
      },
      {
        path: 'onboarding',
        name: 'OnBoardingPage',
        component: AsyncComponent(() => import('../../pages/OnBoardingPage.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'onboarding',
        },
      },
      {
        path: 'statistics',
        name: 'StatisticsPage',
        component: () => import('@/pages/StatisticsPage.vue'),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'statistics',
        },
      },
      {
        path: 'linkMate',
        name: 'LinkMatePage',
        component: AsyncComponent(() => import('../../pages/linkMate/LinkMatePage.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'prospectingSettings',
        name: 'ProspectingToolsSettingsPage',
        component: AsyncComponent(() => import('../../pages/ProspectingToolsSettingsPage.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'dmo',
        name: 'DMOPage',
        component: AsyncComponent(() => import('../../pages/DMOPage.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'dmo',
        },
      },
      {
        path: 'infoPage',
        name: 'InfoPageLinkPage',
        component: AsyncComponent(() => import('../../pages/InfoPageLinkPage.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'infoPages/business',
        name: 'BusinessInfoPageLinkPage',
        component: AsyncComponent(() => import('../../pages/FrazerInfoPageLinkPage.vue')),
        props: { type: 'business' },
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'businessInfoPage',
        },
      },
      {
        path: 'infoPages/product',
        name: 'ProductInfoPageLinkPage',
        component: AsyncComponent(() => import('../../pages/FrazerInfoPageLinkPage.vue')),
        props: { type: 'product' },
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'productInfoPage',
        },
      },
      {
        path: 'leadMagnets',
        name: 'LeadMagnetLinkPage',
        component: AsyncComponent(() => import('../../pages/LeadMagnetLinkPage.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'leadMagnets',
        },
      },
      {
        path: 'broadcast',
        name: 'BroadcastPage',
        component: AsyncComponent(() => import('../../pages/BroadcastPage.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'email-lists/:currentEmailListPropId?',
        name: 'EmailListsPage',
        component: AsyncComponent(() => import('../../pages/EmailListsPage.vue')),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'supportCenter',
        name: 'SupportCenter',
        component: AsyncComponent(() => import('../../pages/HelpCenter.vue')),
        meta: { requiresAuth: false },
      },
      {
        path: 'login',
        name: 'HostLogin',
        component: AsyncComponent(() => import('../../pages/HostLogin.vue')),
        meta: { requiresAuth: false },
        beforeEnter: (to, from, next) => {
          const userStore = useUserStore();
          if (userStore.loggedInInfo?.jwt && userStore.loggedInInfo?.user?.tenant) {
            const nextPageName = to.name && to.name != `HostLogin` ? to.name : `${userStore.loggedInInfo.user.tenant.tenant}DashboardHome`;
            next({ name: nextPageName });
          }
          next();
        },
      },
      {
        path: 'checkout/:checkoutSessionId?',
        name: 'StripeCheckoutPage',
        component: AsyncComponent(() => import('../../pages/StripeCheckoutPage.vue')),
        meta: { requiresAuth: false, isFullPage: true },
        props: true,
      },
      {
        path: 'forgotPassword',
        name: 'ForgotPassword',
        component: AsyncComponent(() => import('../../pages/ForgotPassword.vue')),
        meta: { requiresAuth: false },
        props: route => ({ token: route.query.token }),
      },
      {
        path: 'register',
        name: 'RegisterPage',
        component: AsyncComponent(() => import('../../pages/RegisterPage.vue')),
        meta: { requiresAuth: false },
        props: route => ({ showAllTenants: route.query.showAllTenants == 'true' }),
      },
      {
        path: 'userflow/help/:help',
        name: 'UserflowHelp',
        props: true,
        component: AsyncComponent(() => import('../../pages/UserflowHelperPage.vue')),
        meta: { requiresAuth: true },
      },
      {
        path: 'ketones/:id/:lang?',
        name: 'ChatBotRedirect',
        props: true,
        component: AsyncComponent(() => import('../../pages/ChatBotRedirect.vue')),
        meta: { requiresAuth: false },
      },
      {
        path: ':pathMatch(.*)*',
        name: 'PageNotFound',
        component: AsyncComponent(() => import('../../pages/PageNotFound.vue')),
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: `/link/:linkName`,
    name: `LinkMateLayout`,
    component: AsyncComponent(() => import('../../pages/linkMate/LinkMateLayout.vue')),
    meta: {
      requiresAuth: false,
      isFullPage: true,
    },
    children: [
      {
        path: ``,
        name: `LinkMateLeadPage`,
        component: AsyncComponent(() => import('../../pages/linkMate/LinkMateLeadPage.vue')),
        props: true,
      },
      {
        path: 'info/info-form/:benefit?',
        name: 'ProductInfoPageQuestionaire',
        component: AsyncComponent(() => import('../../pages/linkMate/InfoPageQuestionaire.vue')),
        props: query => ({ ...query.params, type: 'product' }),
        alias: 'ketoinfo/info-form/:benefit?',
      },
      {
        path: 'info/solution/:benefit/:leadId',
        name: 'KetoinfoSolutionPage',
        component: AsyncComponent(() => import('../../pages/linkMate/UnicornPageViewer.vue')),
        props: query => ({ ...query.params, type: 'product' }),
        alias: 'ketoinfo/solution/:benefit/:leadId',
      },
      {
        path: ':slug',
        name: 'LeadMagnetPage',
        component: AsyncComponent(() => import('../../pages/linkMate/LeadMagnetPage.vue')),
        props: query => ({ ...query.params }),
      },
      {
        path: 'video/:videoId',
        name: 'ExternalVideoPage',
        component: AsyncComponent(() => import('../../pages/ExternalVideoPage.vue')),
        props: query => ({ ...query.params }),
      },
      {
        path: 'info-page/info/:type',
        name: 'ExternalInfoPageWarm',
        component: AsyncComponent(() => import('../../pages/linkMate/ExternalWarmInfoPage.vue')),
        props: route => ({
          ...route.params,
          type: route.params.type,
          subtype: 'warm',
          isPreview: route.query.isPreview == 'true',
          language: route.query.language,
          clickOriginData: route.query.clickOriginData == 'linkMate',
        }),
      },
      {
        path: 'info-page/:type',
        name: 'ExternalInfoPageCold',
        component: AsyncComponent(() => import('../../pages/linkMate/ExternalColdInfoPage.vue')),
        props: route => ({
          ...route.params,
          type: route.params.type,
          subtype: 'cold',
          unlocked: route.query.unlocked == 'unlocked',
          isPreview: route.query.isPreview == 'true',
          language: route.query.language,
          clickOriginData: route.query.clickOriginData == 'linkMate',
        }),
      },
    ],
  },
];
