import { AxiosError } from 'axios';
import { AccountLanguage } from './useLanguageService';
import * as Sentry from '@sentry/vue';
import { useUserStore } from '@/stores/userStore';

export interface StrapiError {
  data: null;
  error: {
    status: number;
    name: string;
    message: string;
    details?: {
      errors?: {
        message: string;
        name: string;
        path: string[];
      }[];
      message_key?: string;
      language?: AccountLanguage;
      user?: string;
    };
  };
}

export function useHandleError() {
  function handleError(error: unknown) {
    const errorMessages: string[] = [];
    if (!isAxiosError(error)) {
      throwError(error);
      return;
    }

    if (!error.response?.data.error) {
      throwError(error);
      return;
    }

    if (
      error.response?.data.error.name !== 'ApplicationError' &&
      error.response?.data.error.name !== 'ValidationError' &&
      error.response?.data.error.name !== 'ForbiddenError'
    ) {
      throwError(error);
      return;
    }

    if (error.response?.data.error.details?.message_key) {
      const errorDetails = error.response?.data.error.details;
      errorMessages.push(JSON.stringify(errorDetails));
      return errorMessages;
    }

    if (error.response?.data.error.details?.errors) {
      const responseErrors = error.response?.data.error.details?.errors;
      responseErrors.forEach(element => {
        errorMessages.push(element.message);
      });
      return errorMessages;
    }

    if (error.response?.data.error.message) {
      errorMessages.push(error.response?.data.error.message);
      return errorMessages;
    }
    throwError(error);
    return;
  }

  function throwError(error: unknown) {
    const userStore = useUserStore();
    const scope = new Sentry.Scope();
    scope.setContext('UserStore', userStore.loggedInInfo?.user || {});
    Sentry.captureException(error, scope);
  }

  function isAxiosError(error: unknown): error is AxiosError<StrapiError> {
    return (error as AxiosError<StrapiError>)?.isAxiosError === true;
  }

  function checkForKnownError(error: unknown, expectedErrorMessage: string) {
    if (!isAxiosError(error)) {
      return false;
    }
    return error.response?.data.error.message === expectedErrorMessage;
  }

  function checkForKnowErrorMessageKey(error: unknown, expectedErrorMessageKey: string) {
    if (!isAxiosError(error) || !error.response?.data.error.details?.message_key) {
      return false;
    }
    return error.response?.data.error.details?.message_key === expectedErrorMessageKey;
  }

  function handleAuthErrors(error: unknown) {
    if (!isAxiosError(error)) {
      return [];
    }
    const authErrorsMap = new Map([
      ['provider_disabled', 'This provider is disabled'],
      ['invalid_credentials', 'Invalid identifier or password'],
      ['email_not_confirmed', 'Your account email is not confirmed'],
      ['account_blocked', 'Your account has been blocked by an administrator'],
      ['authentication_required', 'You must be authenticated to reset your password'],
      ['invalid_current_password', 'The provided current password is invalid'],
      ['same_password', 'Your new password must be different than your current password'],
      ['passwords_mismatch', 'Passwords do not match'],
      ['invalid_reset_code', 'Incorrect code provided'],
      ['registration_disabled', 'Register action is currently disabled'],
      ['default_role_not_found', 'Impossible to find the default role'],
      ['duplicate_identifier', 'Email or Username are already taken'],
      ['already_confirmed', 'Already confirmed'],
      ['user_blocked', 'User blocked'],
      ['invalid_token', 'Invalid token'],
    ]);

    const errorMessage = error.response?.data.error.message;
    const keys = [];
    for (const [key, value] of authErrorsMap) {
      if (value === errorMessage) {
        keys.push(key);
      }
    }
    return keys;
  }

  return {
    handleError,
    throwError,
    checkForKnownError,
    checkForKnowErrorMessageKey,
    handleAuthErrors,
  };
}
